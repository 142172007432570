<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-container fluid class="h-100 pa-0 white">
    <v-row no-gutters class="fill-height" justify="center" align="center">
      <v-col cols="12" lg="6" class="d-none d-lg-flex fill-height flex-column justify-center">
        <v-img v-if="$vuetify.breakpoint.lgAndUp" :src="backGroundImage" />
      </v-col>
      <div v-if="$vuetify.breakpoint.mdAndDown" class="p-abs h-100">
        <v-img max-height="100%" :src="backGroundImage" />
      </div>
      <v-col cols="12" lg="6">
        <v-card flat :color="$vuetify.breakpoint.mdAndDown ? 'white' : 'transparent'" class="mx-auto pa-5" :class="$vuetify.breakpoint.mdAndDown ? 'pa-5' : ''" min-width="320" width="400">
          <v-form ref="login" lazy-validation @submit.prevent="submit">
            <v-col cols="12">
              <v-img :src="require('@/assets/img/maPread_logo_color.svg')" max-width="250px" width="350"
                     class="mb-3 mx-auto" max-height="70px" contain
              />
            </v-col>
            <!--            <v-col cols="12">-->
            <!--              <select-locale />-->
            <!--            </v-col>-->
            <v-col cols="12">
              <v-text-field ref="email" v-model="email" persistent-placeholder :maxlength="80" :label="$t('login.email')" outlined dense hide-details="auto" :rules="[$rules.required]" type="email" />
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="password" persistent-placeholder :maxlength="200" :label="$t('login.password')" outlined dense hide-details="auto"
                            :type="showPassword ? 'text' : 'password'"
                            :append-icon="showPassword ? '$eyeSlash' : '$eye'"
                            :rules="[$rules.required]"
                            @click:append="showPassword = !showPassword"
              />
              <div class="d-flex justify-end mt-1 text-caption">
                <v-btn x-small text class="secondary--text" @click="ValidateAccount">{{ $t('login.useCode') }}</v-btn>
                <v-btn x-small text class="primary--text" @click="resetPassword">{{ $t('login.lostPassword') }}</v-btn>
              </div>
            </v-col>
            <v-col v-show="invalidCredentials" cols="12">
              <div class="error--text text-caption">{{ $t('login.errorMessage') }}</div>
            </v-col>
            <v-card-actions class="d-flex justify-end">
              <v-btn type="submit" small color="primary" :loading="submitting" :disabled="submitting">{{ $t('login.signIn') }}</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
      <div class="footer pa-3 caption">
        <span class="secondary--text">ma</span><b :class="$vuetify.breakpoint.mdAndDown ? 'white--text' : 'primary--text'">pread</b><span :class="$vuetify.breakpoint.mdAndDown ? 'white--text' : 'primary--text'"> - v{{ applicationVersion }}</span>
      </div>
    </v-row>
  </v-container>
</template>

<script>
  import {mapState} from 'vuex'
  // import SelectLocale from '@/modules/backoffice/components/SelectLocale.vue'
  export default {
    name: 'Login',
    // components: {
    //   SelectLocale
    // },
    props: {
      targetRoute: Object,
      hint: String
    },
    data() {
      return {
        valid: true,
        submitting: false,
        invalidCredentials: false,
        email: this.hint,
        password: '',
        showPassword: false
      }
    },
    computed: {
      ...mapState('layout', ['customDomain']),
      backGroundImage() {
        return this.customDomain !== '' ? '/img/assets/'+this.customDomain+'/login-default-bg.jpg' : require('@/assets/img/login-default-bg.png')
      },
      applicationVersion() {
        return process.env.APPLICATION_VERSION
      }
    },
    watch: {
      email() {
        this.email = this.email.toLowerCase()
        this.invalidCredentials = false
      },
      password() {
        this.invalidCredentials = false
      }
    },
    mounted() {
      this.$refs.email.$refs.input.focus()
    },
    methods: {
      async submit() {
        if (!this.$refs.login.validate()) {
          return false
        }
        try {
          this.submitting = true
          await this.$auth.login(this.email, this.password)

          try {
            await this.$router.push(this.targetRoute ? this.targetRoute : {name: 'home'})
          } catch (e) {
            if(!e._isRouter) {
              throw e
            }
          }
        } catch (e) {
          if (e && e.response && e.response.status === 401) {
            this.invalidCredentials = true
          } else {
            throw e
          }
        } finally {
          this.submitting = false
        }
      },
      ValidateAccount() {
        this.$router.push({name: 'init-password'})
      },
      resetPassword() {
        this.$router.push({name: 'reset-password'})
      }
    }
  }
</script>

<style scoped>
  .footer {
    position: absolute;
    bottom: 0;
    right: 0;
  }
</style>
